import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import ErrorPage from '@jwp/ott-ui-react/src/components/ErrorPage/ErrorPage';
import RootErrorPage from '@jwp/ott-ui-react/src/components/RootErrorPage/RootErrorPage';
import About from '@jwp/ott-ui-react/src/pages/About/About';
import Home from '@jwp/ott-ui-react/src/pages/Home/Home';
import Search from '@jwp/ott-ui-react/src/pages/Search/Search';
import User from '@jwp/ott-ui-react/src/pages/User/User';
import LegacySeries from '@jwp/ott-ui-react/src/pages/LegacySeries/LegacySeries';
import MediaScreenRouter from '@jwp/ott-ui-react/src/pages/ScreenRouting/MediaScreenRouter';
import PlaylistScreenRouter from '@jwp/ott-ui-react/src/pages/ScreenRouting/PlaylistScreenRouter';
import Layout from '@jwp/ott-ui-react/src/containers/Layout/Layout';
import { PATH_ABOUT, PATH_CONTENT_LIST, PATH_LEGACY_SERIES, PATH_MEDIA, PATH_PLAYLIST, PATH_SEARCH, PATH_USER } from '@jwp/ott-common/src/paths';
import { PLAYLIST_TYPE } from '@jwp/ott-common/src/constants';

import RoutesContainer from '#src/containers/RoutesContainer/RoutesContainer';

export default function AppRoutes() {
  const { t } = useTranslation('error');
  const [searchParams] = useSearchParams();
  const [inplayer_token, setinplayer_token] = useState('');
  const [paymentintentdone, setpaymentintentdone] = useState(false);
  const pi_id = searchParams.get("payment_intent");

  useEffect(() => {
   const _inplayer_token = localStorage.getItem('inplayer_token');
    if (_inplayer_token) {
      let __inplayer_token = JSON.parse(_inplayer_token);
      setinplayer_token(__inplayer_token?.token);
    }
  }, []);


  if (pi_id && ! paymentintentdone ) {
    console.log(inplayer_token);
    fetch(`https://services.inplayer.com/payments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Authorization': 'token from local storage (inplayer_token) or from the JWPAPIService.getToken()'
          'Authorization': `Bearer ${inplayer_token}`
        },
        body: new URLSearchParams({
            pi_id,
        })
      }).then( (response) => {
        setpaymentintentdone(true);
      });
  }

  // const auth = JSON.parse(window.localStorage.getItem("inplayer_token")) ;
  // fetch("https://services.inplayer.com/payments", { method: "POST", headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: `Bearer ${auth.token}`, }, body: new URLSearchParams({ pi_id, }), })


  
  const sca_redirect_url = searchParams.get("sca_redirect_url");
  if(sca_redirect_url){
    window.location.href = atob(sca_redirect_url);
  }
  return (
    <Routes>
      <Route element={<RoutesContainer />}>
        <Route element={<Layout />} errorElement={<RootErrorPage />}>
          <Route index element={<Home />} />
          <Route path={PATH_PLAYLIST} element={<PlaylistScreenRouter type={PLAYLIST_TYPE.playlist} />} />
          <Route path={PATH_CONTENT_LIST} element={<PlaylistScreenRouter type={PLAYLIST_TYPE.content_list} />} />
          <Route path={PATH_MEDIA} element={<MediaScreenRouter />} />
          <Route path={PATH_LEGACY_SERIES} element={<LegacySeries />} />
          <Route path={PATH_SEARCH} element={<Search />} />
          <Route path={PATH_USER} element={<User />} />
          <Route path={PATH_ABOUT} element={<About />} />
          <Route
            path="/*"
            element={<ErrorPage title={t('notfound_error_heading', 'Not found')} message={t('notfound_error_description', "This page doesn't exist.")} />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
